<template>
  <div class="row">
    <div class="col-12 col-lg-8 c g">
        <div class="card">
            <div class="card-header">
                اعدادات ارسال الغياب والتأخر
            </div>
            <div class="card-body">
                <h5>
                    الحضور
                </h5>
                <b-form-checkbox
                    v-model="settings.send_attend"
                    value="direct"
                    :unchecked-value="'-'"
                >
                    ارسال الحضور تلقائياً
                </b-form-checkbox>
                <b-form-checkbox
                    :unchecked-value="'-'"
                    v-model="settings.send_attend"
                    value="undirect"
                >
                    ارسال الحضور مجدول
                </b-form-checkbox>
                <div class="col-12 col-lg-6 g" v-if="settings.send_attend == 'direct' || settings.send_attend == 'undirect'">
                    <div class="form-group">
                    <label for="">رسالة الحضور</label>
                    <textarea class="form-control" v-model="settings.attend_message" rows="3"></textarea>
                    </div>
                </div>
                <br>
                <h5>
                    التأخر
                </h5>
                <b-form-checkbox
                    v-model="settings.send_late" :unchecked-value="'-'"
                    value="direct"
                >
                    ارسال التأخر تلقائياً
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="settings.send_late" :unchecked-value="'-'"
                    value="undirect"
                >
                    ارسال التأخر مجدول
                </b-form-checkbox>
                <div class="col-12 col-lg-6 g" v-if="settings.send_late == 'direct' || settings.send_late == 'undirect'">
                    <div class="form-group">
                    <label for="">رسالة التأخر</label>
                    <textarea class="form-control" v-model="settings.late_message" rows="3"></textarea>
                    </div>
                </div>
                <br>
                <h5>
                    الغياب
                </h5>
                <b-form-checkbox
                    v-model="settings.send_ghiab" :unchecked-value="'-'"
                    value="direct"
                >
                    ارسال الغياب تلقائياً <strong class="text-danger dnone">(جاري الصيانة... برجاء ارسال الغياب يدوياً او مجدول حالياً)</strong>
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="settings.send_ghiab" :unchecked-value="'-'"
                    value="undirect"
                >
                    ارسال الغياب مجدول
                </b-form-checkbox>
                <div class="col-12 col-lg-6 g" v-if="settings.send_ghiab == 'direct' || settings.send_ghiab == 'undirect'">
                    <div class="form-group">
                    <label for="">رسالة الغياب</label>
                    <textarea class="form-control" v-model="settings.ghiab_message" rows="3"></textarea>
                    </div>
                    <b-form-checkbox
                        v-model="settings.ghiab_send_link" :unchecked-value="'-'"
                        value="yes"
                    >
                        ارسال رابط الرد على الغياب
                    </b-form-checkbox>
                </div>
                <div class="form-group col-12 col-lg-6 g" v-if="settings.send_ghiab == 'direct'">
                <h5 for="">وقت ارسال الغياب </h5>
                <input type="time" v-model="settings.ghiab_send_time"
                    class="form-control">
                    <small><strong class="text-danger">* يجب ان يكون بعد الوقت المحدد لإحتساب الغياب</strong></small>
                </div>
                <br>
                <h5>
                    الانصراف
                </h5>
                <b-form-checkbox
                    v-model="settings.send_end" :unchecked-value="'-'"
                    value="direct"
                >
                    ارسال الانصراف تلقائياً
                </b-form-checkbox>
                <div class="col-12 col-lg-6 g" v-if="settings.send_end == 'direct' || settings.send_end == 'undirect'">
                    <div class="form-group">
                    <label for="">رسالة الانصراف</label>
                    <textarea class="form-control" v-model="settings.end_message" rows="3"></textarea>
                    </div>
                </div>
                <br>
                <h5>
                    الاستئذان
                </h5>
                <b-form-checkbox :unchecked-value="'-'"
                    v-model="settings.send_ex"
                    value="direct"
                >
                    ارسال الاستئذان تلقائياً
                </b-form-checkbox>
                <div class="col-12 col-lg-6 g" v-if="settings.send_ex == 'direct' || settings.send_exa == 'undirect'">
                    <div class="form-group">
                    <label for="">رسالة الاستئذان</label>
                    <textarea class="form-control" v-model="settings.ex_message" rows="3"></textarea>
                    </div>
                </div>
                <div class="col-12 g" v-if="settings.send_ex == 'undirect' || settings.send_attend == 'undirect' ||settings.send_late == 'undirect' ||settings.send_ghiab == 'undirect' ||settings.send_end == 'undirect'">
                    <div class="row">
                        <div class="col-12 g">
                            <br>
                            الارسال المجدول
                        </div>
                        <div class="form-group col-12 col-lg-6">
                        <label for="">متى تريد ان يصلك تأكيد الارسال؟</label>
                        <input type="time" v-model="settings.undirect_time"
                            class="form-control">
                        </div>
                        <div class="form-group col-12 col-lg-6">
                        <label for="">رقم الواتساب الذي سيصلك عليه رابط التأكيد</label>
                        <input type="text" v-model="settings.undirect_phone"
                            class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-12 g" v-if="settings.send_ex == 'direct' || settings.send_attend == 'direct' ||settings.send_late == 'direct' ||settings.send_ghiab == 'direct' ||settings.send_end == 'direct'">
                    <div class="border g">
                        <br>
                        ارسال الرسائل التلقائية عبر:
                        <div class="col-12 g">
                            <div class="row">
                                <div :class="'col-12 col-lg-6 g text-center method ' + (settings.send_ghiab_method == 'whatsapp' ? 'active' : '')" @click="settings.send_ghiab_method = 'whatsapp';">
                                    <i class="fa fa-whatsapp"></i>
                                    ارسال عبر الواتس التلقائي
                                </div>
                                <div :class="'col-12 col-lg-6 g text-center method ' + (settings.send_ghiab_method == 'sms' ? 'active' : '')" @click="settings.send_ghiab_method = 'sms';">
                                    <i class="fa fa-phone"></i>
                                    ارسال عبر SMS
                                </div>
                            </div>
                        </div>
                        <div class="col-12 g" v-if="settings.send_ghiab_method == 'whatsapp'">
                            <center>
                                يمكنك التأكد من اعدادات الواتساب من خلال خدمة الواتس التلقائي
                            </center>
                        </div>
                        <div class="col-12 g" v-if="settings.send_ghiab_method == 'sms'">
                            <center>
                                يمكنك ضبط اعدادات SMS من خلال صفحة "اعدادات الSMS"
                            </center>
                        </div>
                    </div>
                </div>
                <b-form-checkbox :unchecked-value="'-'"
                    v-model="settings.no_convert_message_to_hijri"
                    value="yes"
                >
                    ارسال التاريخ في الرسائل بالتاريخ الميلادي بدلاً من الهجري.
                </b-form-checkbox>
                <div class="col-12 g text-center">
                    <button class="btn btn-primary" @click="save()">
                        <i class="fa fa-save"></i>
                        حفظ الاعدادات
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            settings: {
                direct: true
            }
        }
    },
    created(){
        if(!checkPer("students|settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getInfo();
    },
    methods: {
        getInfo(){
            var g = this;
             $.post(api + '/user/students/send-settings', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.settings = r.response
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
        save(){
            var g = this;
             $.post(api + '/user/students/save-send-settings', {
                jwt: this.user.jwt,
                settings: g.settings
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الحفظ", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
    }
}
</script>

<style>
.def:hover{
    background: #eee !important;
    cursor: pointer;
}
.def.active{
    border: 2px solid green !important;
    background: #fafafa;
}
.def{
    border: 2px solid #eee !important;
}
.def.active h5{
    color: green !important;
}
.method:hover{
    background: #eee !important;
    cursor: pointer;
}
.method.active{
    border: 2px solid green !important;
    background: #fafafa;
}
.method{
    border: 2px solid #eee !important;
}
</style>